import { ref } from "vue";
import { UserState } from "../userState";

const userState = ref<UserState>({
  loading: 1,
  isSignedIn: false
});

export function useUserState() {
  return { userState }
};
