import { AuthControl, AuthControlImpl } from "../authControl";
import { useUserState } from "./userState";

const { userState } = useUserState();
let authControl : AuthControl;

export function useAuthControl() {
  if (!authControl) {
    authControl = new AuthControlImpl(userState);    
    authControl.startui();
  }
  
  return { authControl, userState }
};
