import { 
  AdminServices, 
  Services,
} from "../services";

let services: Services;
let adminServices: AdminServices;

export function provideServices(providedServices: Services) {
  services = providedServices;
}

export function hasServices() {
  return !!services;
}


export function provideAdminServices(providedAdminServices: AdminServices) {
  adminServices = providedAdminServices;
}

export function useServices() {
  return { ...services }
};

export function useAdminServices() {
  return { ...adminServices }
};
