import firebase from 'firebase/compat/app';
import { 
  User, 
  getAuth, 
  onAuthStateChanged, 
  signInAnonymously, 
  signInWithCredential, 
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  IdTokenResult,
} from "firebase/auth";

import { Ref, computed } from 'vue';
import { UserState } from './userState';
import { googleLogout } from "vue3-google-login"
import { getResponseJSON } from './services/serviceUtils';

const apiKey = import.meta.env.VITE_FIREBASE_API_KEY;
const authDomain = import.meta.env.VITE_FIREBASE_AUTH_DOMAIN;
const apiServer = import.meta.env.VITE_ERTP_API_SERVER;
let signInModal: any;


export type AuthChangeObserver = (signedIn: boolean) => void;
export type DataMigrationObserver = () => Promise<void>;
export interface AuthControl {
  onAuthStateChanged(authChangeObserver: AuthChangeObserver): void,
  onDataMigrationComplete(dataMigrationObserver: DataMigrationObserver): void,
  getIdToken(forceRefresh?: boolean): Promise<string | undefined>,
  getIdTokenResult(forceRefresh?: boolean): Promise<IdTokenResult>,
  signInAnonymously(): Promise<void>,
  signInWithCredential(token: string): Promise<void>,
  signInWithEmailAndPassword(emailAddress: string, password: string, url: string): Promise<void>,
  createUserWithEmailAndPassword(emailAddress: string, password: string, url: string): Promise<void>,
  sendPasswordResetEmail(emailAddress: string, url: string): Promise<void>,
  sendEmailVerification(url: string): Promise<void>
  signIn(signInModalParam: any): void,
  signOut(): Promise<void>,
  startui(): void,
  initialAuth: Promise<boolean>,
  isLoading: Ref<boolean>
}

export class AuthControlImpl implements AuthControl {
  private userState: Ref<UserState>;
  private firebaseApp;
  private hasInitialAuthState = false;
  private resolveInitialAuth!: (value: boolean) => void;
  private dataMigrationObservers: Array<DataMigrationObserver> = [];
  public readonly initialAuth: Promise<boolean>;
  public readonly isLoading = computed<boolean>(() => (this.userState.value.loading > 0));

  constructor(userState: Ref<UserState>) {
    this.userState = userState;
    const authControl = this;

    this.initialAuth = new Promise((resolve, reject) => {
      authControl.resolveInitialAuth = resolve;

    });

    this.firebaseApp = firebase.initializeApp({
      apiKey,
      authDomain,
    });

  }

  async getIdToken(forceRefresh?: boolean) {
    try {
      await this.initialAuth;
      return await getAuth(this.firebaseApp).currentUser?.getIdToken(forceRefresh);
    } catch (error) {
      throw new Error("Unable to verify authorization, please try again later.");
    }
  }

  async getIdTokenResult(forceRefresh?: boolean) {
    if (forceRefresh) this.userState.value.loading++;
    try {
      return await getAuth(this.firebaseApp).currentUser!.getIdTokenResult(forceRefresh);
    } catch (error) {
      throw new Error("Unable to verify authorization, please try again later.");
    } finally {
      if (forceRefresh) this.userState.value.loading--;
    }
  }

  onDataMigrationComplete(dataMigrationObserver: DataMigrationObserver): void {
    this.dataMigrationObservers.push(dataMigrationObserver);
  };

  async transferDataToUser(originalUserIdToken: string, userId: string) {
    console.log('Transfering anonymous user data to newly signed in user.')
    
    // We had an original, anonymous user, need to transfer their data to the newly signed in user.
    const response: Response = await fetch(
      `${apiServer}/v1/roadtrips`,
      {
        method: "PATCH",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${originalUserIdToken}`
        },
        body: JSON.stringify({userId})
      }
    );

    await getResponseJSON(response);

    await Promise.all(this.dataMigrationObservers.map((observer) => observer()));

    console.log('Data transfer complete..')
      
  }
  
  async signInAnonymously() {
    await signInAnonymously(getAuth(this.firebaseApp))
  }

  async signInWithCredential(token: string) {
    const originalUser = getAuth(this.firebaseApp).currentUser;
    const credential = GoogleAuthProvider.credential(token);
    const userState = this.userState;

    userState.value.loading++;
    try {
      const originalUserIdToken = originalUser?.isAnonymous ? await originalUser.getIdToken() : undefined;

      const userCredential = await signInWithCredential(getAuth(this.firebaseApp), credential);
  
      const userId = userCredential!.user!.uid;
  
      if (originalUserIdToken && userId) {
        await this.transferDataToUser(originalUserIdToken, userId);        
      }
  
      if (signInModal) {
        // Hiding modal
        signInModal.hide();
      }        
    } finally {
      userState.value.loading--;
    }
  }
 
  async createUserWithEmailAndPassword(emailAddress: string, password: string, url: string) {
    const auth = getAuth(this.firebaseApp);
    const originalUser = auth.currentUser;

    const userState = this.userState;

    userState.value.loading++;
    try {
      const originalUserIdToken = originalUser?.isAnonymous ? await originalUser.getIdToken() : undefined;

      const userCredential = await createUserWithEmailAndPassword(auth, emailAddress, password);

      sendEmailVerification(userCredential.user, { url });

      const userId = userCredential!.user!.uid;

      if (originalUserIdToken && userId) {
        await this.transferDataToUser(originalUserIdToken, userId);
      }

      if (signInModal) {
        // Hiding modal
        signInModal.hide();
      }
    } finally {
      userState.value.loading--;
    }
  }

  async signInWithEmailAndPassword(emailAddress: string, password: string, url: string) {
    const auth = getAuth(this.firebaseApp);
    const originalUser = auth.currentUser;

    const userState = this.userState;

    userState.value.loading++;
    try {
      const originalUserIdToken = originalUser?.isAnonymous ? await originalUser.getIdToken() : undefined;

      const userCredential = await signInWithEmailAndPassword(auth, emailAddress, password);

      const userId = userCredential!.user!.uid;


      if (originalUserIdToken && userId) {
        await this.transferDataToUser(originalUserIdToken, userId);
      }

      if (signInModal) {
        // Hiding modal
        signInModal.hide();
      }
    } finally {
      userState.value.loading--;
    }
  }

  async sendPasswordResetEmail(emailAddress: string, url: string) {
    const auth = getAuth(this.firebaseApp);
    return sendPasswordResetEmail(auth, emailAddress, { url })
  }

  async sendEmailVerification(url: string) {
    this.userState.value.loading++;
    try {
      await sendEmailVerification(getAuth(this.firebaseApp).currentUser!, { url });
    } finally {
      this.userState.value.loading--;
    }
  }

  signIn(signInModalParam: any) {
    signInModal = signInModalParam;
    signInModal.show();
  }

  async signOut() {
    googleLogout();
    await getAuth(this.firebaseApp).signOut();
  };
  
  onAuthStateChanged(authChangeObserver: AuthChangeObserver) {
    onAuthStateChanged(getAuth(this.firebaseApp),
      (user) => {
        authChangeObserver(!!user);
    });
  }


  startui() {

    const userState = this.userState;
    const authControl = this;
    
    function authStateChanged(user: User | null) {

      if (!authControl.hasInitialAuthState) {
        // The userState is initally loading, until we know the auth state, which allows
        // us to avoid making initial assumptions
        userState.value.loading--;
        authControl.hasInitialAuthState = true;
        authControl.resolveInitialAuth(true);
      }

      userState.value.isSignedIn = !!user;
      userState.value.isAnonymous = user?.isAnonymous;
      userState.value.photoURL = user?.photoURL ?? undefined;
      userState.value.displayName = user?.displayName ?? undefined;
    }

    function authStateChangeError(error: Error) {
      console.log('Error in auth state change')
      console.error(error);
    }

    onAuthStateChanged(getAuth(this.firebaseApp), authStateChanged, authStateChangeError);

  };
}




