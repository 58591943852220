// Common utility functions shared among the various services
export type ResponseJSON = {
  status?: string // TODO(tjohns): Enumerate these
  message?: string
}

export async function getResponseJSON(response: Response): Promise<ResponseJSON> {

  const contentType = response.headers.get('content-type')?.split(';')[0]?.trim();
  let error;

  if (response.status < 200 || response.status >= 400) {
    error = new Error(`Unexpected status in response: ${response.status}`);
  };

  if (contentType != "application/json") {
    error = error || new Error(`Unexpected content-type in response: ${contentType}`);
  }

  let responseJSON;
  try {
    responseJSON = await response.json();

    if (!responseJSON) {
      error = error || new Error('No response.');
    }
    if (responseJSON.status != "ok") {
      error = responseJSON.message ? new Error(responseJSON.message) : error ? error : new Error('Unexpected application-level status in response');
    }
  } catch (e) {
    error = error || e;
  }

  if (error) throw error;

  return responseJSON;
}

